import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Banner1 from "../Components/Banners/Banner1";
import Banner2 from "../Components/Banners/Banner2";
import Banner3 from "../Components/Banners/Banner3";
import OurMenu from "../pages/OurMenu/OurMenu";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import KozeFranchise from "../pages/KozeFranchise/KozeFranchise";
import Terms from "../pages/Polices/Terms";
import Policy from "../pages/Polices/Policy";
import BannerSlide from "../Components/Banners/BannerSlide";
import ScrollToTop from "../Components/ScrollToTop";

const PublicRoutes = () => {
	return (
		<>
			<BrowserRouter>
				{/* <ScrollToTop /> */}
				<Routes>
					{/* <Route path="/" element={<Home />} /> */}
					<Route path="/" element={<Home />} />
					<Route path="/banner-1" element={<Banner1 />} />
					<Route path="/banner-2" element={<Banner2 />} />
					<Route path="/banner-3" element={<Banner3 />} />
					<Route path="/our-menu" element={<OurMenu />} />
					<Route path="/about-us" element={<About />} />
					<Route path="/contact-us" element={<Contact />} />
					<Route path="/koze-franchise" element={<KozeFranchise />} />
					<Route path="/terms-and-conditions" element={<Terms />} />
					<Route path="/privacy-policy" element={<Policy />} />
					{/* <Route path="/slider" element={<BannerSlide />} /> */}
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default PublicRoutes;
