import React, { useEffect, useState } from "react";
import {
	facebook,
	headerImg,
	instagram,
	logo,
	twitter,
	youtube,
} from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { fadeIn } from "react-animations";

const Header = (props) => {
	const navigate = useNavigate();
	const { Setclose, close } = props;
	// const [close, Setclose] = useState(false);
	// useEffect(() => {
	// 	const menu = document.querySelector("#toggle");
	// 	const menuItems = document.querySelector("#overlay");
	// 	const menuContainer = document.querySelector(".menu-container");
	// 	const menuIcon = document.querySelector("header#top .off-canvas-btn .menu");

	// 	const toggleMenu = async (e) => {
	// 		e.preventDefault();
	// 		// console.log(menuItems.classList.toggle("open"));
	// 		if (menuItems != null) {
	// 			await menuItems.classList.toggle("open");
	// 		}
	// 		if (menuContainer != null) {
	// 			await menuContainer.classList.toggle("full-menu");
	// 		}
	// 		if (menuIcon != null) {
	// 			await menuIcon.classList.toggle("fa-bars");
	// 			await menuIcon.classList.add("fa-times");
	// 		}
	// 	};

	// 	return () => {
	// 		menu.addEventListener("click", toggleMenu, false);
	// 	};
	// }, []);
	const styles = {
		fadeIn: {
			animation: "x 1s",
		},
	};
	return (
		<>
			{/* header starts here */}
			<header id="top">
				<div className="container-fluid px-0">
					<div className="row align-items-center">
						<div className="col-lg-4">
							{/* <div className="off-canvas-btn">
								<button className="header-btn">
									<svg
										width="26"
										height="19"
										viewBox="0 0 26 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect width="26" height="3" rx="1.5" fill="black" />
										<rect y="8" width="22" height="3" rx="1.5" fill="black" />
										<rect y="16" width="17" height="3" rx="1.5" fill="black" />
									</svg>
								</button>
							</div> */}
							{/* <div className="off-canvas-btn"> */}
							<div
								className={
									close ? "menu-container full-menu" : "menu-container"
								}
								id="toggle"
							>
								<a href="#" className="menu" onClick={() => Setclose(!close)}>
									<svg
										width="26"
										height="19"
										viewBox="0 0 26 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect width="26" height="3" rx="1.5" fill="black" />
										<rect y="8" width="22" height="3" rx="1.5" fill="black" />
										<rect y="16" width="17" height="3" rx="1.5" fill="black" />
									</svg>
								</a>
								{/* </div> */}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="header-logo-wrapper text-center">
								<a href="/">
									<img src={logo} className="img-fluid" />
								</a>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-2">
							<div className="header-dropdown-wrapper">
								{/* <button>
									Store <span>location 1</span>
								</button> */}
								{/* <select className="form-control">
									<option value="Select a Location">Select a Location</option>
									<option value="Location 1">Location 1</option>
									<option value="Location 2">Location 2</option>
									<option value="Location 3">Location 3</option>
								</select> */}
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</header>
			{/* off canvar starts here */}
			<div className={close ? "overlay open" : "overlay "} id="overlay">
				<div className="off-canvas-wrapper">
					<div className="container-fluid pe-0">
						<div className="row">
							<div className="col-lg-12">
								<div className="close-btn-wrapper">
									<button
										className="btn-closes animate__animated animate__fadeInLeft"
										onClick={() => Setclose(!close)}
									>
										<i className="fa fa-close"></i>
									</button>
								</div>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-lg-1"></div>
							<div className="col-lg-4">
								<div className="off-canvas-menu-wrapper">
									{close ? (
										<ul>
											<li className="wow animate__animated animate__fadeInLeft">
												<div className="menu-item-wrapper">
													<Link to="/" onClick={() => Setclose(!close)}>
														Koze <span>Home</span>
													</Link>
													<p>Placerat orci nulla pellentesque dignissi</p>
												</div>
											</li>
											<li className="wow animate__animated animate__fadeInLeft">
												<div className="menu-item-wrapper">
													<Link to="/about-us" onClick={() => Setclose(!close)}>
														About <span>Us</span>
													</Link>
													<p>Placerat orci nulla pellentesque dignissi</p>
												</div>
											</li>
											<li className="wow animate__animated animate__fadeInLeft">
												<div className="menu-item-wrapper">
													<Link to="/our-menu" onClick={() => Setclose(!close)}>
														Our <span>Menu</span>
													</Link>
													<p>Placerat orci nulla pellentesque dignissi</p>
												</div>
											</li>
											<li className="wow animate__animated animate__fadeInLeft">
												<div className="menu-item-wrapper">
													<Link
														to="/koze-franchise"
														onClick={() => Setclose(!close)}
													>
														Koze <span>Franchises</span>
													</Link>
													<p>Placerat orci nulla pellentesque dignissi</p>
												</div>
											</li>
											<li className="wow animate__animated animate__fadeInLeft">
												<div className="menu-item-wrapper">
													<Link
														to="/contact-us"
														onClick={() => Setclose(!close)}
													>
														Contact <span>Us</span>
													</Link>
													<p>Placerat orci nulla pellentesque dignissi</p>
												</div>
											</li>
										</ul>
									) : (
										""
									)}
								</div>
							</div>
							<div className="col-lg-7">
								{close ? (
									<figure>
										<img
											src={headerImg}
											style={styles}
											className="img-fluid wow animate__animated animate__fadeInRight"
											alt=""
										/>
									</figure>
								) : (
									""
								)}
							</div>
						</div>
						<div className="off-last-content-wrapper">
							<div className="row align-items-center">
								<div className="col-lg-1"></div>
								<div className="col-lg-5">
									{close ? (
										<div className="off-menu-policy-wrapper wow animate__animated animate__fadeInLeft">
											<ul>
												<li>
													<Link
														to="/terms-and-conditions"
														onClick={() => Setclose(!close)}
													>
														Terms And <span>Conditions</span>
													</Link>
												</li>
												<li>
													<Link
														to="/privacy-policy"
														onClick={() => Setclose(!close)}
													>
														Privacy <span>Policy</span>
													</Link>
												</li>
											</ul>
										</div>
									) : (
										""
									)}
								</div>
								<div className="col-lg-5">
									{close ? (
										<div className="off-social-icons-wrapper wow animate__animated animate__fadeInRight">
											<ul>
												<li>
													<p>
														Follow <span>us</span>
													</p>
												</li>
												<li>
													<Link to="#">
														<img src={facebook} className="img-fluid" alt="" />
													</Link>
												</li>
												<li>
													<Link to="#">
														<img src={instagram} className="img-fluid" alt="" />
													</Link>
												</li>
												<li>
													<Link to="#">
														<img src={youtube} className="img-fluid" alt="" />
													</Link>
												</li>
												<li>
													<Link to="#">
														<img src={twitter} className="img-fluid" alt="" />
													</Link>
												</li>
											</ul>
										</div>
									) : (
										""
									)}
								</div>
								<div className="col-lg-1"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* off canvar ends here */}
			{/* header ends here */}
		</>
	);
};

export default Header;
