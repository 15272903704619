import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import {
	banner1,
	banner2,
	banner3,
	bannerImg1,
	bannerImg2,
	bannerImg3,
	bannerImg4,
} from "../../constant";

const Banner3 = () => {
	return (
		<>
			<Header />
			{/* banner starts here */}
			<section className="banner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="banner-1-text-wrapper">
								<h2>
									Personalize <span>Shake</span>
								</h2>
								<p>
									We’ve Created a brand tailored towards Gen-Z that allows them
									to interact with the products in an innovative new fashion.
									Customers have the ability to customize their milkshakes with
									selfies, personalized messages, and more by using their smart
									phones
								</p>
								<div className="btn-wrapper">
									<Link to="/" className="btn">
										<span>Order Now</span>
										<span className="btn-icon">
											<i className="fa-solid fa-angle-right"></i>
										</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="banner-1-img-wrapper">
								<figure>
									<img src={banner3} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-1">
							<div className="banner-side-img-wrapper">
								<ul>
									<li>
										<img src={bannerImg1} className="banner-side" alt="" />
									</li>
									<li>
										<img src={bannerImg2} className="banner-side" alt="" />
									</li>
									<li>
										<img src={bannerImg3} className="banner-side" alt="" />
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="banner-social-links-wrapper">
								<ul>
									<li>
										<Link to="/">FACEBOOK</Link>
									</li>
									<li>
										<Link to="/">INSTAGRAM</Link>
									</li>
									<li>
										<Link to="/">YOUTUBE</Link>
									</li>
								</ul>
							</div>
							{/* <div className="video-btn-wrapper">
                        <button>
                            <span>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                Maiores, recusandae.
                            </span>
                            <img src={playVideo} className="img-fluid" alt="" />
                        </button>
                    </div> */}
							<div className="scroll-btn-wrapper">
								<button>
									<i className="fa-solid fa-arrow-left-long"></i> SCROLL DOWN
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* banner ends here */}
			<Footer />
		</>
	);
};

export default Banner3;
