import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/style.css";
import {
	AppScanner,
	aboutImg,
	aboutImg2,
	afterbanner,
	allcookies,
	app1,
	app2,
	appleImg,
	banner1,
	banner2,
	banner3,
	bannerImg1,
	bannerImg2,
	bannerImg3,
	bannerImg4,
	check,
	coinImg,
	comboImg,
	comboImg1,
	comboImg2,
	comboImg3,
	comboImg4,
	comboImg5,
	comboImg6,
	cook1,
	cook2,
	cook3,
	cook4,
	cook5,
	cook6,
	cookies,
	cookies1,
	cookies2,
	cookies3,
	cookies4,
	cookies5,
	cookies6,
	emotionImg,
	facebook,
	googleImg,
	locationGif,
	playKozeImg,
	playVideo,
	printImg,
	review1,
	review2,
	review3,
	review4,
	review5,
	shake1,
	shake2,
	shake3,
	shake4,
	shake5,
	shake6,
	shakeEmoji,
	videoImg,
} from "../../constant";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import PlayGame from "../../Components/PlayGame/PlayGame";
import EarnLoyalty from "../../Components/EarnLoyalty/EarnLoyalty";
import Fullpage, {
	FullPageSections,
	FullpageSection,
} from "@ap.cx/react-fullpage";
// import { fullpageApi } from "@fullpage/react-fullpage";
import { FullpageNavigation } from "@ap.cx/react-fullpage";
import AOS from "aos";
// import "aos/dist/aos.css";
import "animate.css";
import "react-animation/dist/keyframes.css";
import { TypeAnimation } from "react-type-animation";

// import { Link as Linking, animateScroll as scroll } from "react-scroll";

const Home = () => {
	const homeSir = useRef(null);
	const [close, Setclose] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");
	const [showSlide, SetshowSlide] = useState(false);
	const [anime, setanime] = useState(false);

	const handleSlide = () => {
		SetshowSlide(true);
	};

	const handleClick = (event) => {
		// console.log("image is clicked", event.target.src);
		const imgUrl = event.target.src;
		setSelectedImage(imgUrl);
		setanime(true);
		return imgUrl;
	};
	useEffect(() => {
		var trigger = gsap.to("#tagline", {
			x: 100,
			duration: 2,
			ease: "bounce",
			delay: 1,
			scrollTrigger: {
				trigger: "#tagline",
				markers: true,
			},
		});
		console.log(trigger);
	}, []);
	const KozeCookies = [
		{
			id: 1,
			name: "shake 1",
			img: cookies1,
		},
		{
			id: 2,
			name: "shake 2",
			img: cookies2,
		},
		{
			id: 3,
			name: "shake 3",
			img: cookies3,
		},
		{
			id: 4,
			name: "shake 4",
			img: cookies4,
		},
		{
			id: 5,
			name: "shake 5",
			img: cookies5,
		},
		{
			id: 6,
			name: "shake 6",
			img: cookies6,
		},
	];
	const comboshake = [
		{
			id: 1,
			name: "Combo 1",
			img: comboImg1,
		},
		{
			id: 2,
			name: "Combo 2",
			img: comboImg2,
		},
		{
			id: 3,
			name: "Combo 3",
			img: comboImg3,
		},
		{
			id: 4,
			name: "Combo 4",
			img: comboImg4,
		},
		{
			id: 5,
			name: "Combo 5",
			img: comboImg5,
		},
		{
			id: 6,
			name: "Combo 6",
			img: comboImg6,
		},
	];
	const shakecookies = [
		{
			id: 1,
			img: cook1,
		},
		{
			id: 2,
			img: cook2,
		},
		{
			id: 3,
			img: cook3,
		},
		{
			id: 4,
			img: cook4,
		},
		{
			id: 5,
			img: cook5,
		},
		{
			id: 6,
			img: cook6,
		},
	];
	const images = [aboutImg, aboutImg2, aboutImg, aboutImg2];

	const [currentImage, setCurrentImage] = useState("");
	const [scroll, setScroll] = useState(false);

	const scrollToNextSection = () => {
		scroll.scrollTo(window.innerHeight); // Scrolls to the next section
	};

	function scrollToSection(sectionId) {
		const section = document.getElementById(sectionId);
		section.scrollIntoView({ behavior: "smooth" });
	}
	// const scrollToNextSection = () => {
	// 	window.scrollTo(window.innerHeight); // Scrolls to the next section
	// };

	useEffect(() => {
		window.addEventListener("scroll", () => {
			var scroll = window.scrollY;
			let element = document.querySelector(".menu-container");
			if (scroll >= 500) {
				element.classList.add("menu-down");
			} else {
				element.classList.remove("menu-down");
			}
			// console.log(scroll++);
		});
	}, []);
	useEffect(() => {
		// AOS.init();

		const intervalId = setInterval(() => {
			setCurrentImage(images[Math.floor(Math.random() * images.length)]);
		}, 2000);

		return () => clearInterval(intervalId);
	}, []);

	const ScrollHandler = (e) => {
		e.preventDefault();
		setScroll(!scroll);
		setTimeout(() => {
			setScroll(!scroll);
		}, 2000);
	};

	return (
		<>
			<Header Setclose={Setclose} close={close} />

			{/* <div id="pagepiling"> */}
			{/* desktopForceStep={true} */}
			<Fullpage className="full section">
				<FullpageNavigation />
				<FullPageSections className="sectiosn-wrapper">
					{/* banner starts here */}
					{/* <FullpageSection
						style={{
							height: "100vh",
						}}
					> */}

					{!showSlide ? (
						<FullpageSection
							style={{
								height: "100vh",
								transition: "all 0.4s ease-in-out",
							}}
						>
							<section
								className={!showSlide ? "banner banner-trans" : "banner-trans "}
							>
								<section id="ban" className="ban">
									<div className="container">
										<section className="banns">
											<div className="row">
												<div className="col-lg-12">
													<div className="banner-content-wrapper">
														<h1 className="animate__animated animate__fadeInDown ">
															The Original <span>Selfie Shake</span>
														</h1>
														<TypeAnimation
															sequence={[
																// Same substring at the start will only be typed out once, initially
																"Upload Photo",
																3000, // wait 1s before replacing "Mice" with "Hamsters"
																"Personalize Shake",
																3000,
																"Voila! Enjoy",
																3000,
															]}
															wrapper="span"
															speed={50}
															style={{
																fontSize: "2em",
																display: "inline-block",
															}}
															className="pink"
															repeat={Infinity}
														/>
													</div>
												</div>
											</div>
											<div className="banner-imgs-wrapper">
												<div className="row">
													<div className="col-lg-1"></div>
													<div className="col-lg-10">
														<div className="row">
															<div className="col-lg-3">
																<div className="banner-img-wrapper animate__animated animate__fadeInLeft ">
																	<figure>
																		<img
																			src={bannerImg1}
																			className="img-fluid"
																			alt=""
																			onClick={handleSlide}
																		/>
																	</figure>
																</div>
															</div>
															<div className="col-lg-3">
																<div className="banner-img-wrapper animate__animated animate__zoomIn ">
																	<figure>
																		<img
																			src={bannerImg2}
																			className="img-fluid"
																			alt=""
																			onClick={handleSlide}
																		/>
																	</figure>
																</div>
															</div>
															<div className="col-lg-3">
																<div className="banner-img-wrapper animate__animated animate__zoomIn ">
																	<figure>
																		<img
																			src={bannerImg3}
																			className="img-fluid"
																			alt=""
																			onClick={handleSlide}
																		/>
																	</figure>
																</div>
															</div>
															<div className="col-lg-3">
																<div className="banner-img-wrapper animate__animated animate__fadeInRight ">
																	<figure>
																		<img
																			src={bannerImg4}
																			className="img-fluid"
																			alt=""
																			onClick={handleSlide}
																		/>
																	</figure>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-1"></div>
												</div>
											</div>
											{/* <div className="row">
												<div className="col-lg-12">
													<div className="btn-wrapper text-center">
														<Link to="/" className="btn">
															<span>Order Now</span>
															<span className="btn-icon">
																<i className="fa-solid fa-angle-right"></i>
															</span>
														</Link>
													</div>
												</div>
											</div> */}
										</section>

										<div className="row">
											<div className="col-lg-12">
												<div className="banner-social-links-wrapper">
													<ul>
														<li>
															<Link to="/">FACEBOOK</Link>
														</li>
														<li>
															<Link to="/">INSTAGRAM</Link>
														</li>
														<li>
															<Link to="/">YOUTUBE</Link>
														</li>
													</ul>
												</div>
												{/* <div className="vio-wrap">
													<div className="video-btn-wrapper">
														<svg viewBox="0 0 100 100" width="174" height="174">
															<defs>
																<path
																	id="circle"
																	d="M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
																/>
															</defs>
															<animateTransform
																attributeName="transform"
																attributeType="XML"
																type="rotate"
																dur="10s"
																values="0 0 0;360 0 0;"
																repeatCount="indefinite"
															/>
															<text fontSize="17">
																<textPath href="#circle">
																	Watch Video Now • Watch Video Now •
																</textPath>
															</text>
														</svg>
														<img src={videoImg} alt="" />
													</div>
												</div> */}

												<div className="scroll-btn-wrapper">
													{/* <a
														href="#tagline"
														onClick={(e) => ScrollHandler(e)}
														// onClick={scrollToNextSection}
													>
														<i className="fa-solid fa-arrow-left-long"></i>{" "}
														SCROLL DOWN
													</a> */}
													{/* <Link
													activeClass="active"
													to="section2"
													spy={true}
													smooth={true}
													offset={0}
													duration={500}
													className="arrow-down"
												
												>
													TEFXFBDb
												</Link> */}
												</div>
											</div>
										</div>
									</div>
								</section>
							</section>
						</FullpageSection>
					) : (
						""
					)}

					{showSlide ? (
						<FullpageSection
							style={{
								height: "100vh",
								transition: "all 0.4s ease-in-out",
							}}
						>
							<section className="banner bannert2">
								<section className="banner-1-sec ban">
									<div className="container">
										<div className="row align-items-center">
											<div className="col-lg-6">
												<div className="banner-1-text-wrapper ms-lg-5 animate__animated animate__fadeInLeft">
													<h2>
														Order From <span>App</span>
													</h2>
													<p>
														We’ve Created a brand tailored towards Gen-Z that
														allows them to interact with the products in an
														innovative new fashion. Customers have the ability
														to customize their milkshakes with selfies,
														personalized messages, and more by using their smart
														phones
													</p>
													{/* <div className="btn-wrapper">
														<Link to="/" className="btn">
															<span>Order Now</span>
															<span className="btn-icon">
																<i className="fa-solid fa-angle-right"></i>
															</span>
														</Link>
													</div> */}
												</div>
											</div>
											<div className="col-lg-5">
												<div
													className="banner-1-img-wrapper text-center "
													onClick={() => SetshowSlide(false)}
												>
													{selectedImage ? (
														<figure>
															<img
																src={selectedImage}
																className={
																	anime
																		? "img-fluid animate__animated  animate__zoomIn"
																		: "img-fluid animate__animated  animate__zoomIn"
																}
																alt=""
															/>
														</figure>
													) : (
														<figure>
															<img
																src={banner1}
																className="img-fluid animate__animated  animate__zoomIn"
																alt=""
															/>
														</figure>
													)}
												</div>
											</div>
											<div className="col-lg-1">
												<div className="banner-side-img-wrapper">
													<ul>
														<li>
															<img
																src={banner1}
																onClick={handleClick}
																className="banner-side img-fluid"
																alt="Order From App"
															/>
														</li>
														<li>
															<img
																src={banner2}
																onClick={handleClick}
																className="banner-side img-fluid"
																alt="Upload Photo"
															/>
														</li>
														<li>
															<img
																src={banner3}
																onClick={handleClick}
																className="banner-side img-fluid"
																alt="Personalize Shake"
															/>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</section>
								<div className="row">
									<div className="col-lg-12">
										<div className="banner-social-links-wrapper">
											<ul>
												<li>
													<Link to="/">FACEBOOK</Link>
												</li>
												<li>
													<Link to="/">INSTAGRAM</Link>
												</li>
												<li>
													<Link to="/">YOUTUBE</Link>
												</li>
											</ul>
										</div>

										{/* <div className="vio-wrap">
											<div className="video-btn-wrapper">
												<svg viewBox="0 0 100 100" width="174" height="174">
													<defs>
														<path
															id="circle"
															d="M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
														/>
													</defs>
													<animateTransform
														attributeName="transform"
														attributeType="XML"
														type="rotate"
														dur="10s"
														values="0 0 0;360 0 0;"
														repeatCount="indefinite"
													/>
													<text fontSize="17">
														<textPath href="#circle">
															Watch Video Now • Watch Video Now •
														</textPath>
													</text>
												</svg>
												<img src={videoImg} alt="" />
											</div>
										</div> */}

										{/* <div className="scroll-btn-wrapper">
											<a href="javascript:void(0)">
												<i className="fa-solid fa-arrow-left-long"></i> SCROLL
												DOWN
											</a>
										</div> */}
									</div>
								</div>
							</section>
						</FullpageSection>
					) : (
						""
					)}

					{/* </FullpageSection> */}
					{/* banner ends here */}
					{/* after banner slide starts here */}

					{/* after banner slide ends here */}
					{/* about starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
						className={scroll ? "scroll-style" : " "}
					>
						<section
							name="section2"
							ref={homeSir}
							className="after-banner"
							id="tagline"
						>
							<div className="container-fluid px-0">
								<div className="row">
									<div className="col-lg-12">
										<div className="after-ban-wrapper">
											<div className="marquee">
												<div>
													<span>
														<figure>
															<img src={afterbanner} className="img-fluid" />
														</figure>
													</span>
													<span>
														<figure>
															<img src={afterbanner} className="img-fluid" />
														</figure>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="about-sec" id="about-sec">
							<div className="container-fluid">
								<div className="row align-items-center">
									<div className="col-lg-6 ps-0 animate__animated animate__fadeInLeft ">
										<div className="container">
											<div className="locations-wrapper">
												<button className="location-btn">
													<img src={locationGif} className="img-fluid" alt="" />
													<h6>
														Location <span>01</span>
													</h6>
												</button>
												<button className="location-btn">
													<img src={locationGif} className="img-fluid" alt="" />
													<h6>
														Location <span>02</span>
													</h6>
												</button>
											</div>
										</div>
										<div className="about-img-wrapper">
											<figure>
												<img
													src={currentImage ? currentImage : aboutImg}
													className="img-fluid"
													alt=""
												/>
											</figure>
										</div>
										<div className="container">
											<div className="locations-wrapper">
												<button className="location-btn">
													<img src={locationGif} className="img-fluid" alt="" />
													<h6>
														Location <span>03</span>
													</h6>
												</button>
												<button className="location-btn">
													<img src={locationGif} className="img-fluid" alt="" />
													<h6>
														Location <span>04</span>
													</h6>
												</button>
											</div>
										</div>
									</div>
									<div className="col-lg-5 animate__animated animate__fadeInRight ">
										<div className="about-content-wrapper  ms-lg-4">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												About <span>Koze Shake</span>
											</h2>
											<p>
												We’ve Created a brand tailored towards Gen-Z that allows
												them to interact with the products in an innovative new
												fashion. Customers have the ability to customize their
												milkshakes with selfies, personalized messages, and more
												by using their smart phones
											</p>
										</div>

										<div className="about-testimonials-wrapper ms-lg-4">
											<div className="about-testimonails-heading-wrapper ">
												<h5>Our Happy Customers</h5>
												<p>Placerat orci nulla pell ente</p>
											</div>
											<div className="about-testimonails-img-wrapper">
												<ul>
													<li>
														<img src={review1} className="img-fluid" alt="" />
													</li>
													<li>
														<img src={review2} className="img-fluid" alt="" />
													</li>
													<li>
														<img src={review3} className="img-fluid" alt="" />
													</li>
													<li>
														<img src={review4} className="img-fluid" alt="" />
													</li>
													<li>
														<img src={review5} className="img-fluid" alt="" />
													</li>
												</ul>
											</div>
										</div>
										<div className="about-content-wrapper ms-lg-4">
											<Link to="/about-us" className="btn">
												<span>Learn More</span>
												<span className="btn-icon">
													<i className="fa-solid fa-angle-right"></i>
												</span>
											</Link>
										</div>
									</div>
									<div className="col-lg-1"></div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* about ends here */}
					{/* koze app starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="koze-app ">
							<div className="container">
								<div className="row mb-5 pb-4">
									<div className="col-lg-3"></div>
									<div className="col-lg-6 text-center">
										<div className="shake-heading-wrapper animate__animated animate__fadeInDown ">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												How To Get <span>Koze</span>{" "}
											</h2>
											<p>
												Mauris auctor eros nec dui blandit pretium. Integer
												iaculis nibh sit amet euismod efficitur. Pellentesque ut
												nulla commodo, dapibus lacus id, pharetra mi. Praesent
											</p>
										</div>
									</div>
									<div className="col-lg-3"></div>
								</div>
								<div className="row">
									<div className="col-lg-6">
										<div className="koze-app-1-wrapper animate__animated animate__fadeInLeft ">
											<div className="koze-app-content-wrapper">
												<h6 className="small-heading">KOZE APP</h6>
												<h4>Order From The App</h4>
												<div className="app-list-wrapper">
													<ul>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>Order from App</span>
														</li>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>Mark arrive on location</span>
														</li>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>grab your shake</span>
														</li>
													</ul>
												</div>
												<div className="buttons-wrapper">
													<Link className="apple-btn" to={"/"}>
														<img src={appleImg} className="img-fluid" />
													</Link>
													<Link className="apple-btn" to={"/"}>
														<img src={googleImg} className="img-fluid" />
													</Link>
												</div>
											</div>
											<div className="koze-app-img-wrapper">
												<img src={app1} className="img-fluid" alt="" />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="koze-app-2-wrapper animate__animated animate__fadeInRight ">
											<div className="koze-app-content-wrapper">
												{/* <h6 className="small-heading">GET KOZE !</h6> */}
												<h6 className="small-heading">KOZE ONLINE</h6>

												<h4>Order From The Location</h4>
												<div className="app-list-wrapper">
													<ul>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>Scan QR Code</span>
														</li>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>Browse the menu</span>
														</li>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>Place order online</span>
														</li>
														<li>
															<img src={check} className="img-fluid" alt="" />{" "}
															<span>grab your shake</span>
														</li>
													</ul>
												</div>
											</div>
											<div className="koze-app-img-wrapper">
												<img src={app2} className="img-fluid" alt="" />
												<img
													src={AppScanner}
													className="app-scanner-img"
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* koze app ends here */}
					{/* print sec starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="print-sec">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6">
										<div className="print-content-wrapper animate__animated animate__fadeInLeft  me-lg-4">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												Print <span>Anything</span>
											</h2>
											<h4>On Top Of Anything</h4>
											<p className="pt-2">
												Placerat orci nulla pellentesque dignissim enim sit amet
												venenatis. Bibendum enim facilisis gravida neque
												convallis. Pharetra diam sit amet nisl suscipit
												adipiscing bibendum est. Ipsum faucibus vitae aliquet
												nec ullamcorper. Euismod quis v
											</p>
											<p>
												vel eros. At quis risus sed vulputate odio. Turpis massa
												tincidunt dui ut ornare lectus sit amet est. Ut pharetra
												sit amet aliqua
											</p>
										</div>
									</div>
									<div className="col-lg-6">
										<figure>
											<img
												src={printImg}
												className="img-fluid animate__animated animate__fadeInRight "
											/>
										</figure>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* print sec ends here */}
					{/* emotions sec starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="emotions-sec">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 animate__animated animate__fadeInLeft ">
										<figure>
											<img src={emotionImg} className="img-fluid" />
										</figure>
									</div>
									<div className="col-lg-6 animate__animated animate__fadeInRight ">
										<div className="emotions-content-wrapper ms-lg-4">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												Express Your <span>Emotions</span>
											</h2>
											<p className="pt-2">
												Placerat orci nulla pellentesque dignissim enim sit amet
												venenatis. Bibendum enim facilisis gravida neque
												convallis. Pharetra diam sit amet nisl suscipit
												adipiscing bibendum est. Ipsum faucibus vita
											</p>
											<p>
												vel eros. At quis risus sed vulputate odio. Turpis massa
												tincidunt dui ut ornare lectus sit amet est. Ut pharetra
												sit am
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* emotions sec ends here */}
					{/* <div className="wrapper"> */}
					{/* play games starts here */}
					{/* <div className="comp comp1"> */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<PlayGame />
					</FullpageSection>
					{/* </div> */}
					{/* play games ends here */}
					{/* earnn loyalty starts here */}
					{/* <div className="comp comp2"> */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<EarnLoyalty />
					</FullpageSection>
					{/* </div> */}
					{/* earnn loyalty ends here */}
					{/* </div> */}
					{/* shake starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="shake-sec">
							<div className="container">
								<div className="row">
									<div className="col-lg-2"></div>
									<div className="col-lg-8 text-center">
										<div className="shake-heading-wrapper animate__animated animate__fadeInDown ">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												Meet Koze <span>Shake</span>{" "}
											</h2>
											<p>
												Mauris auctor eros nec dui blandit pretium. Integer
												iaculis nibh sit amet euismod efficitur. Pellentesque ut
												nulla commodo, dapibus lacus id, pharetra mi. Praesent
											</p>
										</div>
									</div>
									<div className="col-lg-2"></div>
								</div>
								<div className="row">
									<div className="col-lg-6 animate__animated animate__fadeInLeft ">
										<div className="shake-biscuits-wrapper">
											<ul>
												<li>
													<img src={shake1} className="img-fluid" />
												</li>
												<li>
													<img src={shake2} className="img-fluid" />
												</li>
												<li>
													<img src={shake3} className="img-fluid" />
												</li>
												<li>
													<img src={shake4} className="img-fluid" />
												</li>
												<li>
													<img src={shake5} className="img-fluid" />
												</li>
												<li>
													<img src={shake6} className="img-fluid" />
												</li>
											</ul>
										</div>
									</div>
									<div className="col-lg-6 align-self-center">
										<div className="shake-biscuits-wrapper animate__animated animate__fadeInRight ">
											<img src={allcookies} className="img-fluid" alt="" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="button-wrapper text-center">
											<a href="/our-menu" className="btn">
												<span>View All</span>
												<span className="btn-icon">
													<i className="fa-solid fa-angle-right"></i>
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* shake ends here */}
					{/* combo-shake starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="combo-shake">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6">
										<div className="combo-shake-img-wrapper animate__animated animate__fadeInLeft ">
											<figure>
												<img src={comboImg} className="img-fluid" />
											</figure>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="combo-shake-content-wrapper animate__animated animate__fadeInRight ">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												Koze <span>Shake</span>
											</h2>
											<p className="pt-2">
												Mauris auctor eros nec dui blandit pretium. Integer
												iaculis nibh sit amet euismod efficitur. Pellentesque ut
												nulla commodo, dapibus lacus id,
											</p>
											<div className="combo-shake-wrapper">
												<ul>
													{comboshake.map((data) => {
														return (
															<>
																<li key={data?.id}>
																	<div className="combo-shake-card-wrapper">
																		<div className="combo-shake-img-wrapper">
																			<img
																				src={data?.img}
																				className="img-fluid"
																				alt=""
																			/>
																		</div>
																		<div className="combo-shake-koze-heading">
																			<h5>{data?.name}</h5>
																		</div>
																	</div>
																</li>
															</>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* combo-shake ends here */}
					{/* Koze cookies starts here */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
					>
						<section className="koze-cookies">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 animate__animated animate__fadeInLeft ">
										<div className="koze-cookies-img-wrapper">
											<figure>
												<img src={cookies} className="img-fluid" />
											</figure>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="koze-cookies-content-wrapper animate__animated animate__fadeInRight ">
											<h6 className="small-heading">GET KOZE !</h6>
											<h2>
												Koze <span>Cookies</span>
											</h2>
											<p className="pt-2">
												Mauris auctor eros nec dui blandit pretium. Integer
												iaculis nibh sit amet euismod efficitur. Pellentesque ut
												nulla commodo, dapibus lacus id,
											</p>
											<div className="cookies-shake-wrapper">
												<ul>
													{KozeCookies.map((data, index) => {
														return (
															<>
																<li key={data?.id}>
																	<div className="cookies-card-wrapper">
																		<div className="cookies-img-wrapper">
																			<img
																				src={data?.img}
																				className="img-fluid"
																				alt=""
																			/>
																		</div>
																		<div className="cookies-koze-heading">
																			<h5>{data?.name}</h5>
																		</div>
																	</div>
																</li>
															</>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</FullpageSection>
					{/* Koze cookies ends here */}
					{/* </div> */}

					{/* <div className="home-footer-wrapper"> */}
					<FullpageSection
						style={{
							height: "100vh",
						}}
						className="footer-wrapper"
					>
						<Footer />
					</FullpageSection>
					{/* </div> */}
				</FullPageSections>
			</Fullpage>
		</>
	);
};

export default Home;
