import React, { useEffect, useState } from "react";
import PublicRoutes from "./routes/PublicRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadingImg } from "./constant";
import "core-js";
import WOW from "wowjs";

function App() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 8000);
		new WOW.WOW({
			live: false,
		}).init();
	}, []);
	return (
		<React.Fragment>
			{loading ? (
				// <div class="cup">
				// 	<div class="base">
				// 		<div class="left"></div>
				// 		<div class="right"></div>
				// 		<div class="shadow"></div>
				// 	</div>
				// 	<div class="top">
				// 		<div class="left"></div>
				// 		<div class="right"></div>
				// 		<div class="shadow"></div>
				// 	</div>
				// 	<div class="lid"></div>
				// 	<div class="straw"></div>
				// 	<div class="logo">SONIC</div>
				// 	<div class="strip">#codevember Day 1</div>
				// </div>
				<div className="loading-wrapper">
					<img src={loadingImg} className="img-fluid" id="loading-img" alt="" />
				</div>
			) : (
				<PublicRoutes />
			)}
		</React.Fragment>
	);
}

export default App;
