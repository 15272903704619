import React from "react";
import { coinImg } from "../../constant";

const EarnLoyalty = () => {
	return (
		<>
			{/* play games starts here */}
			<section className="earn-game">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="earn-img-wrapper animate__animated animate__fadeInLeft ">
								<figure>
									<img src={coinImg} className="img-fluid" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="earn-game-content-wrapper ms-lg-5 animate__animated animate__fadeInRight ">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Earn Loyalty <span>Points</span>
								</h2>
								<p className="pt-2">
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper. Euismod quis v
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amet aliqua
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* play games ends here */}
		</>
	);
};

export default EarnLoyalty;
