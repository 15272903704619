import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import {
	menucookie1,
	menucookie2,
	menucookie3,
	menucookie4,
	menucookie5,
	menucookie6,
	pro1,
	pro10,
	pro11,
	pro12,
	pro2,
	pro3,
	pro4,
	pro5,
	pro6,
	pro7,
	pro8,
	pro9,
} from "../../constant";
import "../../assets/css/menu.css";
import "animate.css";

const OurMenu = () => {
	const [close, Setclose] = useState(false);
	const [showtab, Setshowtab] = useState(0);
	const KozeShake = [
		{
			id: 1,
			name: "Koze <span>Shake 1</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro1,
		},
		{
			id: 2,
			name: "Koze <span> Shake 2</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro2,
		},
		{
			id: 3,
			name: "Koze <span>Shake 3</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro3,
		},
		{
			id: 4,
			name: "Koze <span>Shake 4</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro4,
		},
		{
			id: 5,
			name: "Koze <span>Shake 5</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro5,
		},
		{
			id: 6,
			name: "Koze <span>Shake 6</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro6,
		},
		{
			id: 7,
			name: "Koze <span>Shake 7</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro7,
		},
		{
			id: 8,
			name: "Koze <span>Shake 8</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro8,
		},
		{
			id: 9,
			name: "Koze <span>Shake 9</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro9,
		},
		{
			id: 10,
			name: "Koze <span>Shake 10</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro10,
		},
		{
			id: 11,
			name: "Koze <span>Shake 11</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro11,
		},
		{
			id: 12,
			name: "Koze <span>Shake 12</span>",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: pro12,
		},
	];
	const KozeCookies = [
		{
			id: 1,
			name: "Koze Cookie 1",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie1,
		},
		{
			id: 2,
			name: "Koze Cookie 2",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie2,
		},
		{
			id: 3,
			name: "Koze Cookie 3",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie3,
		},
		{
			id: 4,
			name: "Koze Cookie 4",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie4,
		},
		{
			id: 5,
			name: "Koze Cookie 5",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie5,
		},
		{
			id: 6,
			name: "Koze Cookie 6",
			des: "Placerat orci nulla pellentesque dignissim enim sit amet ve",
			img: menucookie6,
		},
	];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header Setclose={Setclose} close={close} />
			{/* Menu starts here */}
			<section className="our-menu">
				<div className="container">
					<div className="row">
						<div className="menu-heading-wrapper">
							<h2>
								Our <span>Menu</span>
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="menu-tabs-btn-wrapper">
								<ul>
									<li>
										<button
											className={
												showtab === 0 ? "menu-tab-btn active" : "menu-tab-btn"
											}
											onClick={() => Setshowtab(0)}
										>
											Koze <span>Shakes</span>
										</button>
									</li>
									<li>
										<button
											className={
												showtab === 1 ? "menu-tab-btn active" : "menu-tab-btn"
											}
											onClick={() => Setshowtab(1)}
										>
											Koze <span>Cookies</span>
										</button>
									</li>
								</ul>
							</div>
							{showtab === 0 ? (
								<>
									<div className="menu-tabs-wrapper">
										<div className="row">
											{KozeShake.map((data) => {
												return (
													<div key={data?.id} className="col-lg-3">
														<div className="product-card-wrapper">
															<div className="product-img-wrapper">
																<figure>
																	<a href="#">
																		<img
																			src={data?.img}
																			className="img-fluid animate__animated animate__fadeInBottomRight animate__delay-1s"
																			alt={data?.name}
																		/>
																	</a>
																</figure>
															</div>
															<div className="pro-content-wrapper">
																<h4
																	dangerouslySetInnerHTML={{
																		__html: data?.name,
																	}}
																></h4>
																<p>{data?.des}</p>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</>
							) : (
								<>
									<div className="menu-tabs-wrapper">
										<div className="row">
											{KozeCookies.map((data) => {
												return (
													<div key={data?.id} className="col-lg-3">
														<div className="product-card-wrapper">
															<div className="product-img-wrapper">
																<figure>
																	<a href="#">
																		<img
																			src={data?.img}
																			className="img-fluid"
																			alt={data?.name}
																		/>
																	</a>
																</figure>
															</div>
															<div className="pro-content-wrapper">
																<h4>{data?.name}</h4>
																<p>{data?.des}</p>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* menu ends here */}
			<Footer />
		</>
	);
};

export default OurMenu;
