import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/contact.css";
import { Link } from "react-router-dom";
import {
	arrow,
	contactemail,
	contactlocation,
	contactphone,
	facebook,
	instagram,
	twitter,
	videoImg,
	youtube,
} from "../../constant";

const Contact = () => {
	const [close, Setclose] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header Setclose={Setclose} close={close} />
			{/* contact starts here */}
			<section className="contact-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="contact-heading-wrapper">
								<h2>
									Let’s Get <span>In Touch</span>
								</h2>
								<p>
									Mauris auctor eros nec dui blandit pretium. Integer iaculis
									nibh sit amet euismod efficitur. Pellentesque ut blandit
									pretium. Integer ii blandit pretiium. Integer iacunec
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row pt-5">
						<div className="col-lg-4 px-0">
							<div className="contact-info-wrapper">
								<div className="contact-info-heading-wrapper">
									<h4>
										Contact <span>Information</span>
									</h4>
									<p>
										Mauris auctor eros nec dui blandit pretium. Integer iaculis
										nibh sit amet euismod
									</p>
								</div>
								<div className="contact-list-wrapper">
									<ul>
										<li>
											<Link to="tel:123456789598">
												<span className="contact-icon">
													<img
														src={contactphone}
														className="img-fluid"
														alt=""
													/>
												</span>
												<span className="contact-text">+123 456 789 598</span>
											</Link>
										</li>
										<li>
											<Link>
												<span className="contact-icon">
													<img
														src={contactlocation}
														className="img-fluid"
														alt=""
													/>
												</span>
												<span className="contact-text">
													1057 Neville Street Glendale, Elkhart IN 47511
												</span>
											</Link>
										</li>
										<li>
											<Link to="mailto:kozeshake@example.com">
												<span className="contact-icon">
													<img
														src={contactemail}
														className="img-fluid"
														alt=""
													/>
												</span>
												<span className="contact-text">
													kozeshake@example.com
												</span>
											</Link>
										</li>
									</ul>
								</div>
								<div className="contact-icon-wrapper">
									<ul>
										<li>
											<Link to="#">
												<img src={facebook} className="img-fluid" alt="" />
											</Link>
										</li>
										<li>
											<Link to="#">
												<img src={instagram} className="img-fluid" alt="" />
											</Link>
										</li>
										<li>
											<Link to="#">
												<img src={youtube} className="img-fluid" alt="" />
											</Link>
										</li>
										<li>
											<Link to="#">
												<img src={twitter} className="img-fluid" alt="" />
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-8 px-0">
							<div className="cont-wrapper">
								<div className="contact-form-wrapper">
									<form action="">
										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="firstname">First Name</label>
												<input
													type="text"
													placeholder="Write here"
													name="firstname"
													className="form-control"
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="lastname">Last Name</label>
												<input
													type="text"
													placeholder="Write here"
													name="lastname"
													className="form-control"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-6">
												<label htmlFor="Phone">Phone Number</label>
												<input
													type="text"
													placeholder="Write here"
													name="Phone"
													className="form-control"
												/>
											</div>
											<div className="col-lg-6">
												<label htmlFor="email">Email Address </label>
												<input
													type="text"
													placeholder="Write here"
													name="email"
													className="form-control"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12">
												<label htmlFor="Phone">Your Message</label>
												<textarea
													name=""
													className="form-control"
													id=""
													placeholder="Write here"
													cols="30"
													rows="8"
												></textarea>
											</div>
										</div>
										<div className="form-group">
											<div className="btn-wrapper">
												<Link to="/" className="btn">
													<span>Submit</span>
													<span className="btn-icon">
														<i className="fa-solid fa-angle-right"></i>
													</span>
												</Link>
											</div>
										</div>
									</form>
								</div>
								<div className="vio-wrap">
									<div className="video-btn-wrapper">
										<svg viewBox="0 0 100 100" width="185" height="185">
											<defs>
												<path
													id="circle"
													d="M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
												/>
											</defs>
											<animateTransform
												attributeName="transform"
												attributeType="XML"
												type="rotate"
												dur="10s"
												values="0 0 0;360 0 0;"
												repeatCount="indefinite"
											/>
											<text fontSize="14">
												<textPath href="#circle">
													Get in Touch • Get in Touch • Get in Touch • Get in
													Touch •
												</textPath>
											</text>
										</svg>
										<img src={arrow} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* contact ends here */}
			<Footer />
		</>
	);
};

export default Contact;
