import React from "react";
import { appleImg, googleImg, playKozeImg } from "../../constant";
import { Link } from "react-router-dom";

const PlayGame = () => {
	return (
		<>
			{/* play games starts here */}
			<section className="play-game">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 animate__animated animate__fadeInLeft ">
							<div className="play-game-content-wrapper me-lg-5">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Play Koze <span>Game</span>
								</h2>
								<p className="pt-2">
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper. Euismod quis v
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amet aliqua
								</p>
								<div className="buttons-wrapper">
									<Link className="apple-btn" to={"/"}>
										<img src={appleImg} className="img-fluid" />
									</Link>
									<Link className="apple-btn" to={"/"}>
										<img src={googleImg} className="img-fluid" />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="play-img-wrapper animate__animated animate__fadeInRight ">
								<figure>
									<img src={playKozeImg} className="img-fluid" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* play games ends here */}
		</>
	);
};

export default PlayGame;
