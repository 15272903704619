import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/terms.css";
const Terms = () => {
	const [close, Setclose] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header Setclose={Setclose} close={close} />
			{/* terms starts here */}
			<section className="terms">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="terms-content-wrapper">
								<div className="terms-heading-wrapper">
									<h2>
										Terms And <span>Conditions</span>
									</h2>
								</div>
								<div className="terms-text-wrapper pt-5">
									<p>
										Adipiscing elit duis tristique sollicitudin nibh sit amet
										commodo nulla. Augue lacus viverra vitae congue. Tristique
										et egestas quis ipsum suspendisse ultrices gravida dictum
										fusce. A pellentesque sit amet porttitor eget dolor. Varius
										sit amet mattis vulputate. Id diam vel quam elementum. Nunc
										sed velit dignissim sodales ut. Dolor morbi non arcu risus
										quis varius quam quisque. Posuere morbi leo urna molestie at
										elementum. Urna molestie at elementum eu facilisis sed.
										Semper eget duis at tellus at. Blandit volutpat maecenas
										volutpat blandit aliquam etiam erat velit scelerisque. Amet
										justo donec enim diam vulputate.In egestas erat imperdiet
										sed euismod. Auctor eu augue ut lectus. Ultricies mi quis
										hendrerit dolor. Ornare massa eget egestas purus viverra
										accumsan in nisl. At risus viverra adipiscing at in tellus
										integer feugiat. Volutpat ac tincidunt vitae semper quis
										lectus nulla. Dui sapien eget mi proin sed. Volutpat commodo
										sed egestas egestas fringilla phasellus faucibus scelerisque
										eleifend. Eu consequat ac felis donec et odio pellentesque.
										Neque vitae tempus quam pellentesque nec nam aliquam sem.
										Non sodales neque sodales ut. Quis hendrerit dolor magna
										eget est lorem ipsum dolor. Diam quis enim lobortis
										scelerisque fermentum dui faucibus in. Ultricies mi eget
										mauris pharetra et ultrices neque ornare aenean. Rutrum
										tellus pellentesque eu tincidunt.
									</p>
									<p>
										Lorem sed risus ultricies tristique. Velit scelerisque in
										dictum non consectetur a. Viverra justo nec ultrices dui.
										Turpis in eu mi bibendum neque egestas congue quisque
										egestas. Aliquet lectus proin nibh nisl condimentum id
										venenatis. Felis donec et odio pellentesque diam volutpat
										commodo sed egestas. Sagittis vitae et leo duis ut. Amet
										massa vitae tortor condimentum lacinia quis vel eros.
										Imperdiet nulla malesuada pellentesque elit eget gravida cum
										sociis. Sed risus ultricies tristique nulla aliquet enim.
										Elementum facilisis leo vel fringilla est ullamcorper eget
										nulla. Nibh mauris cursus mattis molestie a iaculis at erat.
										Ultrices dui sapien eget mi proin sed libero enim. Id
										volutpat lacus laoreet non curabitur gravida arcu ac tortor.
										Mauris vitae ultricies leo integer malesuada nunc vel risus.
										Sed augue lacus viverra vitae congue. Amet mauris commodo
										quis imperdiet massa tincidunt nunc pulvinar sapien.
									</p>
									<p>
										Adipiscing elit duis tristique sollicitudin nibh sit amet
										commodo nulla. Augue lacus viverra vitae congue. Tristique
										et egestas quis ipsum suspendisse ultrices gravida dictum
										fusce. A pellentesque sit amet porttitor eget dolor. Varius
										sit amet mattis vulputate. Id diam vel quam elementum. Nunc
										sed velit dignissim sodales ut. Dolor morbi non arcu risus
										quis varius quam quisque. Posuere morbi leo urna molestie at
										elementum. Urna molestie at elementum eu facilisis sed.
										Semper eget duis at tellus at. Blandit volutpat maecenas
										volutpat blandit aliquam etiam erat velit scelerisque. Amet
										justo donec enim diam vulputate.In egestas erat imperdiet
										sed euismod. Auctor eu augue ut lectus. Ultricies mi quis
										hendrerit dolor. Ornare massa eget egestas purus viverra
										accumsan in nisl. At risus viverra adipiscing at in tellus
										integer feugiat. Volutpat ac tincidunt vitae semper quis
										lectus nulla. Dui sapien eget mi proin sed. Volutpat commodo
										sed egestas egestas fringilla phasellus faucibus scelerisque
										eleifend. Eu consequat ac felis donec et odio pellentesque.
										Neque vitae tempus quam pellentesque nec nam aliquam sem.
										Non sodales neque sodales ut. Quis hendrerit dolor magna
										eget est lorem ipsum dolor. Diam quis enim lobortis
										scelerisque fermentum dui faucibus in. Ultricies mi eget
										mauris pharetra et ultrices neque ornare aenean. Rutrum
										tellus pellentesque eu tincidunt.
									</p>
									<p>
										Lorem sed risus ultricies tristique. Velit scelerisque in
										dictum non consectetur a. Viverra justo nec ultrices dui.
										Turpis in eu mi bibendum neque egestas congue quisque
										egestas. Aliquet lectus proin nibh nisl condimentum id
										venenatis. Felis donec et odio pellentesque diam volutpat
										commodo sed egestas. Sagittis vitae et leo duis ut. Amet
										massa vitae tortor condimentum lacinia quis vel eros.
										Imperdiet nulla malesuada pellentesque elit eget gravida cum
										sociis. Sed risus ultricies tristique nulla aliquet enim.
										Elementum facilisis leo vel fringilla est ullamcorper eget
										nulla. Nibh mauris cursus mattis molestie a iaculis at erat.
										Ultrices dui sapien eget mi proin sed libero enim. Id
										volutpat lacus laoreet non curabitur gravida arcu ac tortor.
										Mauris vitae ultricies leo integer malesuada nunc vel risus.
										Sed augue lacus viverra vitae congue. Amet mauris commodo
										quis imperdiet massa tincidunt nunc pulvinar sapien.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* terms ends here */}
			<Footer />
		</>
	);
};

export default Terms;
