import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { franchise, locationGif } from "../../constant";
import "../../assets/css/franchise.css";
import { Link } from "react-router-dom";

const KozeFranchise = () => {
	const [close, Setclose] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header Setclose={Setclose} close={close} />
			{/* Koze franchise starts here */}
			<section className="franchise">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="franchise-content-wrapper">
								<h2>
									Get Yourself A <span>Koze Franchise</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									sque dignissim enim sit amet venenatis. Bibendum enim
									facellentesque dignissim en
								</p>
								<h4 className="mt-4">
									Available <span>Locations</span>
								</h4>
							</div>
							<div className="franchise-btn-wrapper">
								<div className="locations-wrapper">
									<button className="location-btn">
										<img src={locationGif} className="img-fluid" alt="" />
										<h6>
											Location <span>01</span>
										</h6>
									</button>
									<button className="location-btn">
										<img src={locationGif} className="img-fluid" alt="" />
										<h6>
											Location <span>02</span>
										</h6>
									</button>
									<button className="location-btn">
										<img src={locationGif} className="img-fluid" alt="" />
										<h6>
											Location <span>03</span>
										</h6>
									</button>
									<button className="location-btn">
										<img src={locationGif} className="img-fluid" alt="" />
										<h6>
											Location <span>04</span>
										</h6>
									</button>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row py-5">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="franchise-img-wrapper">
								<figure>
									<img src={franchise} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="banner-social-links-wrapper">
								<ul>
									<li>
										<Link to="/">FACEBOOK</Link>
									</li>
									<li>
										<Link to="/">INSTAGRAM</Link>
									</li>
									<li>
										<Link to="/">YOUTUBE</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Koze franchise ends here */}
			{/* franchise form starts here */}
			<section className="franchise-form">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="franchise-form-heading-wrapper">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Register As <span>A Franchise</span>
								</h2>
								<p>
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim
								</p>
							</div>
							<div className="franchise-form-wrapper mt-5">
								<div className="franchise-label-wrapper">
									<h5>
										Personal <span>Information</span>
									</h5>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="firstname">First Name</label>
										<input
											type="text"
											placeholder="Write here"
											name="firstname"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<label htmlFor="lastname">Last Name</label>
										<input
											type="text"
											placeholder="Write here"
											name="lastname"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Phone Number</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<label htmlFor="email">Email Address </label>
										<input
											type="text"
											placeholder="Write here"
											name="email"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Country</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<label htmlFor="email">City</label>
										<input
											type="text"
											placeholder="Write here"
											name="email"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Zipcode</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6"></div>
								</div>
								<div className="franchise-label-wrapper">
									<h5>
										Financial <span>Information</span>
									</h5>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Net Worth</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<label htmlFor="email">Liquid Assets</label>
										<input
											type="text"
											placeholder="Write here"
											name="email"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Credit Score</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6"></div>
								</div>
								<div className="franchise-label-wrapper">
									<h5>
										Professional <span>Information</span>
									</h5>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Employment History</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<label htmlFor="email">Education</label>
										<input
											type="text"
											placeholder="Write here"
											name="email"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<label htmlFor="Phone">Business Experience</label>
										<input
											type="text"
											placeholder="Write here"
											name="Phone"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6"></div>
								</div>
								<div className="form-group">
									<div className="btn-wrapper">
										<Link to="/" className="btn">
											<span>Submit</span>
											<span className="btn-icon">
												<i className="fa-solid fa-angle-right"></i>
											</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* franchise form ends here */}
			<Footer />
		</>
	);
};

export default KozeFranchise;
